
import Vue, { VueConstructor } from "vue";
import KCrudTable, {
  CrudTableHeader,
} from "@/modules/crudTable/components/KCrudTable.vue";
import { ExportType, PaginatedRequest } from "@/application/api/getPaginated";
import { mapGetters } from "vuex";
import RequiredClientDialog from "@/modules/client/components/RequiredClientDialog.vue";
import {
  clientDeliveryIndex,
  DeliveryIndexItem,
} from "@/modules/delivery/api/delivery";
import DeliveryFilter from "@/modules/delivery/components/DeliveryFilter.vue";
import ExportButton from "@/components/ExportButton.vue";
import { DeliveryStatusCode } from "@/modules/delivery/enums";
import eventBus from "@/application/eventBus";

interface ComponentData {
  headers: CrudTableHeader[];
  selected: Partial<DeliveryIndexItem> & { clientId: number; id: number }[];
  exports: ExportType[];
  filterComponent: VueConstructor;
  deliveryIndexData: DeliveryIndexItem[];
}

export default Vue.extend({
  name: "DeliveryTable",
  components: {
    ExportButton,
    RequiredClientDialog,
    KCrudTable,
  },
  data: (): ComponentData => ({
    headers: [
      { value: "clientName" },
      { value: "reference" },
      {
        value: "plannedDate",
        type: "date",
        typeParameters: { dateType: "DD MMM YYYY" },
      },
      {
        value: "inGoodsReceiptArea",
        type: "date",
        typeParameters: { dateType: "DD MMM YYYY HH:mm" },
      },
      { value: "unitLoad" },
      { value: "statusName" },
    ],
    selected: [],
    exports: [],
    filterComponent: DeliveryFilter,
    deliveryIndexData: [],
  }),
  computed: {
    ...mapGetters("authorisation", ["client", "isClient"]),
    ...mapGetters("crudTable", ["getRouteSetting"]),
  },
  watch: {
    client: {
      handler() {
        this.resetTable();
      },
      deep: true,
    },
  },
  methods: {
    async deliveryIndex(data: PaginatedRequest) {
      if (!this.client?.id) throw "Client id is not set";
      const response = await clientDeliveryIndex(data, this.client?.id);
      this.deliveryIndexData = response.data.data;
      this.exports = response.data.export ?? [];
      return response;
    },
    refreshTable(): void {
      (this.$refs.table as any).getData();
    },
    resetTable(): void {
      (this.$refs.table as any).handleReset();
    },
    onRowClick(item: DeliveryIndexItem) {
      if (!this.client?.id) throw "Client id is not set";

      if (
        !item.statusCode ||
        [
          DeliveryStatusCode.New,
          DeliveryStatusCode.InGoodsReceiptArea,
          DeliveryStatusCode.NotReceived,
        ].includes(String(item.statusCode))
      ) {
        this.$router.push({
          name: "delivery.edit",
          params: {
            deliveryId: item.id,
            clientId: item.clientId,
          },
        });
        return;
      }

      if (
        [
          DeliveryStatusCode.Received,
          DeliveryStatusCode.PartlyReceived,
        ].includes(String(item.statusCode))
      ) {
        eventBus.$emit("snackbar", {
          color: "error",
          text: this.$t("delivery.messages.notAvailable"),
          milliseconds: 4000,
        });
      } else {
        this.$router.push({
          name: "delivery.receipt",
          query: { reference: item.reference },
        });
      }
    },
  },
});
